import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Openings from '~/components/openings'
import OpeningsAlt from '~/components/openings/alt'

type OpenHours = {
  day: string
  time: string
}

type Address = {
  line1: string
  line2: string
}

type Frontmatter = {
  showroomTitle: string
  openHours: OpenHours[]
  address: Address
}

type QueryResult = {
  showroom: {
    frontmatter: Frontmatter
  }
}

type LaShowroomOpeningsProps = {
  altLayout?: boolean
}

const showroomQuery = graphql`
  query {
    showroom: markdownRemark(frontmatter: { templateKey: { eq: "showroom" } }) {
      frontmatter {
        showroomTitle
        openHours {
          day
          time
        }
        address {
          line1
          line2
        }
      }
    }
  }
`

const LaShowroomOpenings: FC<LaShowroomOpeningsProps> = props => {
  const {
    showroom: {
      frontmatter: { showroomTitle, openHours, address },
    },
  } = useStaticQuery<QueryResult>(showroomQuery)

  const { altLayout } = props

  return altLayout ? (
    <OpeningsAlt openHours={openHours} title={showroomTitle} address={address} />
  ) : (
    <Openings openHours={openHours} title={showroomTitle} address={address} {...props} />
  )
}

export default LaShowroomOpenings
