// @ts-ignore
import { Box, Heading, Text } from 'theme-ui'
import React, { FC } from 'react'
import Dash from '~/components/dash'
import Table from '~/components/table'

type Props = {
  title: string
  address?: {
    line1: string
    line2: string
  }
  openHours: Array<{
    day: string
    time: string
  }>
  addressInTable?: boolean
}

const Openings: FC<Props> = ({ title, openHours, address, addressInTable = false, ...rest }) => {
  let openHoursNew = openHours.map(item => ({
    firstCol: item.day,
    secondCol: item.time,
  }))

  if (addressInTable && address) {
    openHoursNew = [
      {
        firstCol: 'Address',
        secondCol: `${address.line1}, ${address.line2}`,
      },
      ...openHoursNew,
    ]
  }

  return (
    <Box {...rest}>
      <Heading as="h4" variant="title5">
        {title}
      </Heading>
      <Dash sxProps={{ my: 'md1' }} />
      {address && !addressInTable && (
        <Text variant="small" mb="md2">
          {address.line1}
          <br />
          {address.line2}
        </Text>
      )}
      <Table data={openHoursNew} />
    </Box>
  )
}

export default Openings
