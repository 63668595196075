import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Themed } from '@theme-ui/mdx'

type Props = {
  data: Array<{
    firstCol: any
    secondCol: any
  }>
  tableSxProps?: ThemeUIStyleObject
}

const CONTACT_METHODS_LINKS = {
  email: 'mailto:',
  phone: 'tel:',
}

const Table = ({ data, tableSxProps }: Props) => {
  return (
    <Themed.table
      sx={{
        width: '100%',
        fontSize: 16,
        variant: 'variants.table.primary',
        ...tableSxProps,
        tr: {
          fontSize: 16,
        },
        td: {
          fontSize: 16,

          a: {
            color: '#2B2B2B',
            fontWeight: 200,
            textDecoration: 'none',
          },
        },
      }}
    >
      {data.map((row, index) => {
        const typedRow =
          CONTACT_METHODS_LINKS[row.firstCol.toLowerCase() as keyof typeof CONTACT_METHODS_LINKS]
        return (
          <Themed.tr key={index}>
            <Themed.th sx={{ textTransform: 'uppercase' }}>{row.firstCol}</Themed.th>
            <Themed.td
              sx={{
                textAlign: 'right',
                textTransform: 'uppercase',
                width: ['auto', '50%'],
              }}
            >
              {typedRow ? (
                <Themed.a
                  href={`${typedRow}${row.secondCol.trim().toLowerCase()}`}
                  sx={{
                    textTransform: 'uppercase',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {row.secondCol}
                </Themed.a>
              ) : (
                row.secondCol
              )}
            </Themed.td>
          </Themed.tr>
        )
      })}
    </Themed.table>
  )
}

export default Table
