// @ts-ignore
import { Box, Flex, Grid, Heading, Text } from 'theme-ui'
import React, { FC } from 'react'
import useMediaQuery from '~/hooks/use-media-query'
import Table from '~/components/table'

type Props = {
  title: string
  address?: {
    line1: string
    line2: string
  }
  openHours: Array<{
    day: string
    time: string
  }>
  addressInTable?: boolean
}

const OpeningsAlt: FC<Props> = ({ title, openHours, address, addressInTable = false, ...rest }) => {
  let openHoursNew = openHours.map(item => ({
    firstCol: item.day,
    secondCol: item.time,
  }))

  if (addressInTable && address) {
    openHoursNew = [
      {
        firstCol: 'Address',
        secondCol: `${address.line1}, ${address.line2}`,
      },
      ...openHoursNew,
    ]
  }

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[1]})`,
  })

  return (
    <Box
      {...rest}
      sx={{
        mr: [`auto`, null, `0px`],
        ml: [`auto`, null, `0px`],
        maxWidth: [`450px`, null, `none`],
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: [`1fr`, null, `1.5fr 2fr`],
          gap: ['20px', null, '50px'],
        }}
      >
        <Box
          sx={{
            mb: ['40px', null, '0px'],
            textAlign: [`center`, null, `left`, null],
          }}
        >
          <Heading
            as="h4"
            variant="title5"
            sx={{
              fontSize: [`22px`, null, `22px`],
              textTransform: 'uppercase',
              textAlign: [`center`, null, `left`, null],
              mb: ['10px', null, null],
            }}
          >
            {title}
          </Heading>
          {address && !addressInTable && (
            <Text
              variant="small"
              sx={{
                color: `#666666`,
                textAlign: [`center`, null, `left`, null],
                fontSize: [`12px`, null, `16px`],
                mb: [`md`, null, null],
              }}
            >
              {address.line1}
              {isMobile ? `, ` : <br />}
              {address.line2}
            </Text>
          )}
        </Box>
        <Table data={openHoursNew} />
      </Grid>
    </Box>
  )
}

export default OpeningsAlt
